<template>
   <el-container class="recordadd p15">
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>留样管理</el-breadcrumb-item>
       <el-breadcrumb-item  to="/admin/menu">一级菜单列表</el-breadcrumb-item>
       <el-breadcrumb-item v-if="formInline.menu_id">编辑一级菜单</el-breadcrumb-item>
       <el-breadcrumb-item v-else>新增一级菜单</el-breadcrumb-item>
    </el-breadcrumb>
        <div>
          
          <el-button  class="back_btn"  size="medium" plain @click="$router.back()">返回</el-button>
        </div>
    </el-header>
    <el-main  class="pt0">
      <el-form  :inline="true" status-icon ref="formInline"  :model="formInline"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="菜单排序：" size="medium" >
           <el-input placeholder="请输入菜单排序" v-model="formInline.menu_sort" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="11" class="tl">
        <el-form-item class="w" label-width="28%" label="page_id：" size="medium" prop="page_id" :rules="[{required: true,message: '请输入page_id', trigger: 'blur' }]">
         <el-input placeholder="请输入page_id" v-model="formInline.page_id" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="菜单名称：" size="medium" prop="menu_name" :rules="[{required: true,message: '请输入菜单名称', trigger: 'blur' }]">
           <el-input placeholder="请输入菜单名称" v-model="formInline.menu_name" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
           <el-form-item class="w" label-width="28%" label="菜单图标：" size="medium" >
           <el-input placeholder="请输入菜单图标" v-model="formInline.menu_logo" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="菜单路由连接：" size="medium" >
           <el-input placeholder="请输入菜单路由连接" v-model="formInline.menu_url" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
          <el-col :span="11" class="">
            <el-form-item  class="rules w" size="medium" label-width="28%" label="是否审核后使用："  prop="menu_name" :rules="[{required: true,message: '请选择是否审核后使用', trigger: 'blur' }]">
              <el-select  v-model="formInline.audit_use_sate" clearable placeholder="请选择是否审核后使用">
                <el-option
                  v-for="item in Options2"
                  :key="item.value" :label="item.label" :value="String(item.value)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="22" class="tl" :offset="1">
           <el-button  class="" size="medium" type="primary" @click="submitForm('formInline')">提交</el-button>
        </el-col>
     </el-row>
     
 </el-form>
    </el-main>
   </el-container>
</template>
<script>
import axios from '../../../util/api'
// import { tengxuncloud } from '../../../util/tengxun'
import { local,session} from '../../../util/util'
import {imageUrl} from '../../../config/index'
export default {
  data () {
    return {
        user:'',
      formInline: {
         menu_id:'',
         page_id:"",
        menu_parent_id: '0',
        menu_name: '',
        menu_logo: '',
        menu_url:'',
        menu_level: '1',
        audit_use_sate:''
      },
      Options2:[{ label: "否", value: "1" },{ label: "是", value: "2" }],
    }
  },
   created () {
       this.user = local.get('user');
       this.formInline.menu_id =session.get('params').id?String(session.get('params').id):'';
       if(this.formInline.menu_id){this.init()}
           
  },
  methods: {
       init () {
          axios.get("/pc/menu/single",{menu_id:String(this.formInline.menu_id)}).then(v => {
           this.formInline.page_id=v.data.menu.page_id;
           this.formInline.menu_name=v.data.menu.menu_name;
            this.formInline.menu_sort=v.data.menu.menu_sort;
           this.formInline.menu_logo=v.data.menu.menu_logo;
           this.formInline.menu_url=v.data.menu.menu_url;
           this.formInline.menu_level=v.data.menu.menu_level;
           this.formInline.audit_use_sate=v.data.menu.audit_use_sate;
          });
       },
      
         submitForm(formInline){
         this.$refs[formInline].validate((valid) => {
        if (valid) {
            if(this.formInline.menu_id){
                 axios.put('/pc/menu/update', this.formInline).then((v) => {
      this.$router.back()
      })

            }else{
                axios.post('/pc/menu/save', this.formInline).then((v) => {
      this.$router.back()
      })
            }
        
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      
      },
  }
}
</script>
<style lang="scss" scoped>
.recordadd{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .recordadd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
 /deep/ .el-checkbox-button__inner{    border-left: 1px solid #DCDFE6;
    border-radius: 4px !important;}
   /deep/ .no-header .has-gutter{display: none}
  .people-message .el-form-item { margin-bottom: 0px;}
  .recordadd-upload .el-upload-list__item{width: 160px;height: 160px;float: left;margin-right: 10px;
   /deep/ .el-upload{width: 160px;height: 160px;line-height: 160px;}}
      /deep/ .disabled .el-upload--text {
    display: none !important;
}
}

</style>